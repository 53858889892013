<template>
  <div id="cart">
    <section class="title flex align-center justify-between">
      <div class="text f18 text-bold">购物车 （全部{{ chosenCount || "0" }}）</div>
      <div class="flex align-center f15">
        <div>已选商品（不含运费） <span class="text-orange f20 text-bold">{{ chosenMoney || "0.00" }}</span></div>
        <div class="subBtn ml10  pointer" @click="goPayOrder" :class="[chosenCount>0 ?'bgColor':'']">结算</div>
      </div>
    </section>
    <section class="title1 flex align-center f15 text-bold">
      <div class="flex align-center">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="chooseAll">全选</el-checkbox>
      </div>
      <div>商品信息</div>
      <div>规格</div>
      <div>单价</div>
      <div>数量</div>
      <div>金额</div>
      <div>操作</div>
    </section>
    <el-checkbox-group
      class="list"
      v-infinite-scroll="load"
      infinite-scroll-disabled="disabled"
      infinite-scroll-distance="20"
      v-model="choesnList"
      @change="chooseItem"
    >
      <el-checkbox v-for="(i, index) in shoppingList" :key="index" :label="i" class="product"    >

      <!-- </el-checkbox> -->
        <div class="itemMainInfo">
          <el-image class="img item-center" fit="cover" :src="i.logo" />
          <div class="name item-center f17 ml10" >{{ i.productName }}</div>
        </div>
        <div class="w110 item-center  norme" >{{ i.normName }}</div>
        <div class="w110 item-center">￥{{ i.price }}</div>
        <div class="w110 item-center pl10">
          <div class="flex align-center">
            <i @click.prevent="reduce(index)" class="el-icon-remove-outline mr5 activeIcon" style="font-size: 17px" />
            <div class="num mr5">{{ i.num }}</div>
            <i @click.prevent="add(index)" class="el-icon-circle-plus-outline mr5 activeIcon" style="font-size: 17px" />
          </div>
        </div>
        <div class="w110 item-center text-orange text-bold">￥{{ i.price * i.num }}</div>
        <div class="w110 item-center  pointer" @click.prevent="del(index)">删除</div>
      </el-checkbox>
    </el-checkbox-group>
    <section class="footer flex justify-between align-center f17 text-black">
      <div class="flex align-center">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="chooseAll">全选</el-checkbox>
        <div class="f14 pointer" @click="delSome()">删除选中</div>
      </div>

      <div class="flex justify-between align-center">
        <div class="mr40 f14">
          已选商品<span class="text-red text-bold f17">{{ chosenCount || "0" }}</span
          >件
        </div>
        <div class="f14">合计</div>
        <div class="mr10 f14">（不含运费）:</div>
        <div class="text-red text-bold f17 mr30">{{ chosenMoney || "0.00" }}</div>
        <div class="subBtn pointer" @click="goPayOrder" :class="[chosenCount>0 ?'bgColor':'']">结算</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productName: "",
      price: "",
      productCode: "",
      logo: "",
      productDetail: "",
      typeIndex: "0",
      logo: "",
      shoppingList: [],
      totalMoney:0,
      count: 3,
      loading: false,
      choesnList: [],
      //   全选
      isIndeterminate: false,
      checkAll: false,
      memberCode: "",
    };
  },
  computed: {
    noMore() {
      return this.count >= 10;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    chosenCount() {
      let count = 0;
      this.choesnList.forEach((v) => {
        count += v.num;
      });
      return count;
    },
    chosenMoney() {
      let money = 0;
      this.choesnList.forEach((v) => {
        money += v.num * v.price;
      });
       this.totalMoney=money.toFixed(2)
      return  this.totalMoney;
    },
  },
  methods: {
    load() {
      this.loading = true;
      setTimeout(() => {
        this.count += 1;
        this.loading = false;
      }, 2000);
    },
    reduce(index) {
      if(this.shoppingList[index].num>1){
  this.shoppingList[index].num--;
      var getData = {};
      getData.memberCode = this.memberCode;
      getData.productCode = this.shoppingList[index].productCode;
      getData.normCode = this.shoppingList[index].normCode;
      this.reduceShopping(getData);
      }else{
         this.$message.error({
          message: "数量已经最少了！",
          type: "warning",
          offset:'400',
        });
      }
    
    },
    add(index) {
      this.shoppingList[index].num++;
      this.num++;
      var getData = {};
      getData.memberCode = this.memberCode;
      getData.productCode = this.shoppingList[index].productCode;
      getData.normCode = this.shoppingList[index].normCode;
      this.addShopping(getData);
    },
    chooseItem(val) {
      this.choesnList = val;
    },
    chooseAll(val) {
      this.choesnList = val ? this.shoppingList : [];
      this.isIndeterminate = !this.isIndeterminate;
    },
    //删除单个商品
    del(index) {
      var getData = {};
      getData.memberCode = this.memberCode;
      getData.productCode = this.shoppingList[index].productCode;
      getData.normCode = this.shoppingList[index].normCode;
      if(this.choesnList.length<=0){
          this.$api.notice.deleteShoppingCart(getData).then((res) => {
        if ((res.data.code = 200)) {
          this.queryShopping();
        }
      });
      }
      
    },
    //删除选中的商品（多选）
    delSome() {
      if (this.choesnList.length > 0) {
      this.$api.notice.deleteShopping(this.choesnList).then((res) => {
        if ((res.data.code = 200)) {
          this.queryShopping();
           this.choesnList=[]
        }
      });
       }
     
    },
    //减少商品数量
    reduceShopping(getData) {
      this.$api.notice.reduceShopping(getData).then((res) => {
        if ((res.data.code = 200)) {
        }
      });
    },
    //增加商品数量
    addShopping(getData) {
      this.$api.notice.addShopping(getData).then((res) => {
        if ((res.data.code = 200)) {
        }
      });
    },

    goPayOrder() {
      let a=JSON.stringify(this.choesnList)
      if (this.choesnList.length > 0) {
        this.$router.push({
          path: "/product/payOrder",
          query: {
            productList: a,
            cart: 2,
          },
        });
      }
    },
    queryShopping() {
      var condition = {};
      condition.memberCode = this.memberCode;
      this.$api.notice.queryShopping({ condition }).then((res) => {
        if ((res.data.code = 200)) {
          this.shoppingList = res.data.data.data;
        }
      });
    },
  },
  beforeCreate() {
    this.$nextTick((res) => {
      document.querySelector("#index").setAttribute("style", "background:#eeeeee");
    });
  },
  beforeDestroy() {
    document.querySelector("#index").setAttribute("style", "background:#ffffff");
  },
  created() {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.queryShopping();
  },
};
</script>

<style lang="less" scoped>
*{
  padding:0;
  margin: 0;
}
html,body{
height: 100%;
width: 100%;
padding-bottom:5% ;
}
#cart {
  width: 953px;
  margin: 0 auto;
  padding-top: 30px;
}
.title {
  width: 100%;
  height: 70px;
  padding: 0 15px;
  background: white;
  color: black;
  border-bottom: 2px solid #eeeeee;
  border-radius: 20px 20px 0 0;
}
.title1 {
  background: white;
  padding-left: 15px;
  & > div:nth-child(1) {
    width: 85px;
  }
  & > div:nth-child(2) {
    width: 300px;
  }
  & > div:nth-child(n + 3) {
    width: 110px;
  }
  /deep/.el-checkbox__label {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
}
.list {
  height: 600px;
  // height: auto;
  // border-radius: 15px;
  overflow-x: clip;
  overflow-y: auto;

  .product {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 15px;
    background: white;
    border-bottom: 1px dashed #eeeeee;
    // border: 2px solid #9c9c9c;
    // border-radius: 5px;
    .itemMainInfo {
      display: flex;
      width: 350px;
      .name {
        //换行展示
        white-space: normal;
        //隐藏过长名字为...
        // overflow: hidden;
        // text-overflow: ellipsis;
        flex: 1;
        padding: 0 15px 0 40px;
        width: 100%;
        height: 80;
        text-align: left;
      }
    }
    /deep/.el-checkbox__input {
      align-self: center;
    }
    /deep/.el-checkbox__inner {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
    /deep/.el-checkbox__label {
      display: flex;
      // height: 153px;
      color: black;
    }
    /deep/.el-checkbox__inner::after {
      transition: all 0s;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
      content: "";
      width: 4px;
      height: 15px;
      top: 3px;
      left: 8px;
      transition: all 0s;
    }
    .img {
      width: 80px;
      height: 80px;
    }
    // .spec {
    //   min-width: 138px;
    //   padding: 8px 20px;
    //   border: 2px solid red;
    //   border-radius: 2px;
    // }
    .num {
      min-width: 50px;
      padding: 2px 15px;
      border: 1px solid #9c9c9c;
      text-align: center;
    }
    .activeIcon {
      transition: all 0.1s;
    }
    .activeIcon:hover {
      transform: scale(1.2);
    }
    .rightPrice {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    .price {
      line-height: 153px;
    }
  }
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: skyblue;
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 10px;
  }
}
.footer {
  width: 100%;
  height: 55px;
  padding: 0 15px;
  // position: fixed;
  // bottom: 100px;
  background: white;
  border-radius: 0 0 20px 20px;
  /deep/.el-checkbox {
    margin-right: 60px;
    transform: translateY(2px);
  }
  // /deep/.el-checkbox__inner {
  //   width: 25px;
  //   height: 25px;
  // }
  /deep/.el-checkbox__label {
    font-size: 14px;
    color: black;
  }
  /deep/.el-checkbox__inner::after {
    transition: all 0s;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
    content: "";
    width: 4px;
    height: 15px;
    top: 3px;
    left: 8px;
    transition: all 0s;
  }
  .btn {
    width: 118px;
    height: 57px;
    font-size: 21px;
    color: white;
    background: red;
  }
}
.text-orange {
  color: orangered;
}
.subBtn {
  width: 70px;
  height: 40px;
  background: #969595;
  color: white;
  border-radius: 40px;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
}
.w110 {
  width: 110px;
}
.pl10 {
  padding-left: 10px;
}
.item-center {
  align-self: center;
}
.bgColor{
  background: #409eff;
}
.fixBottom{
  position: fixed;
}
.norme{
  width:110px;
  // background: lightpink;
  white-space: normal;
  height:40px;
    /* 超出行数用... */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* //显示的行数 */
  -webkit-line-clamp:2;
  overflow: hidden;
}
</style>
